import { UrlParamsReplace } from './index';

export const GET_PAYMENT_URL = (opportunityId, entityId) =>
    UrlParamsReplace(
        '/payments?opportunityId=:opportunityId&entityId=:entityId',
        { opportunityId, entityId }
    );

export const GET_PAYMENT_HISTORY_URL = (id, opportunityId, orderId) =>
    UrlParamsReplace(
        '/payment-history/:id?opportunityId=:opportunityId&orderId=:orderId',
        {
            id,
            opportunityId,
            orderId,
        }
    );

export const POST_PAYMENT_GATEWAY_URL = () =>
    UrlParamsReplace('/payment-gateway');

export const GET_PAYMENT_GATEWAY_MID_DETAILS_URL = () => UrlParamsReplace('/payment-gateways-mid-details');

export const GET_PAYMENT_GATEWAY_BY_OPPORTUNITY_ID = (opportunityId) =>
    UrlParamsReplace(
        '/payment-gateway/:opportunityId',
        {
            opportunityId,
        }
    );
export const GET_PAYMENT_ID_URL = (id) =>
    UrlParamsReplace(
        '/payment/:id',
        {
            id,
        }
    );
export const GET_PAYMENT_BY_INVESTMENT_ID_URL = (investmentId) => {
    return UrlParamsReplace('/payment?investmentId=:investmentId', {
        investmentId,
    });
}

export const POST_WALLET_PAYMENT = () => UrlParamsReplace(`/payments/wallet`);

export const CREATE_JIRAAFSAFE_REFUND_OVERSUBSCRIPTION_REPAYMENT = () =>
    UrlParamsReplace(`/payments/oversubscribed-refund`);

export const CREATE_OFFLINE_REFUND_OVERSUBSCRIPTION_REPAYMENT = () =>
    UrlParamsReplace(`/payments/oversubscribed-refund-offline`);

export const GET_PAYMENT_BY_STATUS_URL = (status, limit, page) => {
    return UrlParamsReplace('/payment/status/:status?limit=:limit&page=:page', {
        status,
        limit,
        page,
    });
};

export const GET_ELIGIBLE_PAYMENT_METHODS_URL = (investmentId) => {
    return UrlParamsReplace(
        '/eligible-payment-methods?investmentId=:investmentId',
        {
            investmentId,
        }
    );
};

export const GET_PAYMENT_STATUS_URL = (orderReferenceId) => {
    return UrlParamsReplace(
        '/payments/status?orderReferenceId=:orderReferenceId',
        {
            orderReferenceId,
        }
    );
};

export const VERIFY_UPI_URL = () => {
    return UrlParamsReplace('/upi/verify');
};

export const INITIATE_UPI_PAYMENT_URL = () => {
    return UrlParamsReplace('/upi');
};

export const PAYMENT_STATUS_URL = (investmentId) => {
    return UrlParamsReplace('/investments/:investmentId/payment-status', {
        investmentId
    });
};

export const CREATE_PAYMENT_URL = () => {
    return UrlParamsReplace('/payments');
}

export const RETRY_PAYMENT_URL = (paymentId) => {
    return UrlParamsReplace('/payment/:paymentId/retry', {
        paymentId
    });
}

export const PAYMENT_SESSION_VALIDITY_URL = ({ investmentId, paymentSessionId }) => {
    return UrlParamsReplace('/investment/:investmentId/payment-history/valid-session?paymentSessionId=:paymentSessionId', { paymentSessionId, investmentId });
}
