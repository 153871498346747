import { Text } from '@ui/atoms';

const OpportunitiesOverviewHeader = ({ title, description, button }) => {
    return (
        <div className="z-10 flex flex-col w-full gap-3 px-4 sm:flex-row sm:items-end sm:justify-between xl:px-0">
            <div className="flex flex-col gap-2">
                <Text
                    content={title}
                    className="text-center text-primary-500 h4-semibold sm:text-left"
                />
                {description && (
                    <Text
                        content={description}
                        className="text-center text-gray-600 p5-regular sm:text-left"
                    />
                )}
            </div>
            {button && (
                <div className="flex justify-center md:justify-start">
                    {button}
                </div>
            )}
        </div>
    );
};

export default OpportunitiesOverviewHeader;
