export const CONSTANTS = {
    MIN_MAX_AMOUNT: [0, 100000],
    MIN_INVESTMENT: 0,
    MAX_INVESTMENT: 100000,
    STEP: 1000,
    MIN_STEP_BTW_THUMBS: 10,
};

export const FILTER_OPTIONS = {
    yield: [
        { label: 'Upto 8%', value: '<8' },
        { label: '8-10%', value: '8-10' },
        { label: '10-12%', value: '10-12' },
        { label: '>12%', value: '>12' },
    ],
    tenure: [
        { label: 'Upto 1Y', value: '<1' },
        { label: '1-3Y', value: '1-3' },
        { label: '>3Y', value: '>3' },
    ],
    rating: [
        { label: 'High', value: 'high' },
        { label: 'Medium', value: 'medium' },
        { label: 'Low', value: 'low' },
    ],
};

export const SORT_OPTIONS = [
    { label: 'Default', value: 'default' },
    { label: 'Yield', value: 'yield' },
    { label: 'Tenure', value: 'tenure' },
    { label: 'Rating', value: 'rating' },
    {
        label: 'Min Investment',
        value: 'amount',
    },
];

export const VALID_KEYS = {
    yield: FILTER_OPTIONS.yield.map((option) => option.value),
    tenure: FILTER_OPTIONS.tenure.map((option) => option.value),
    rating: FILTER_OPTIONS.rating.map((option) => option.value),
    sort: SORT_OPTIONS.map((option) => option.value),
};

export const opportunitiesFilterDefaultValues = {
    yield: [],
    tenure: [],
    amount: CONSTANTS.MIN_MAX_AMOUNT,
    rating: [],
    secured: false,
};

export const customMinInvestmentSteps = [0, 10000, 25000, 50000, 75000, 100000];