const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => {
            urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]);
        });
    }
    return urlWithPrefix;
};

export const getUrlFromMultipleQueryParams = (
    url,
    queryParams = null,
    split = true
) => {
    if (queryParams) {
        const queryParamKeys = Object.keys(queryParams).filter(
            (key) => queryParams[key] !== ''
        );

        if (queryParamKeys.length > 0) {
            const queryParamsString = queryParamKeys
                .map((key) => {
                    if (
                        typeof value === 'string' &&
                        value.includes(',') &&
                        split
                    ) {
                        console.log('i am here');

                        // Handle st
                        // rings with commas: Split into multiple key=value pairs
                        return value
                            .split(',')
                            .map((v) => `${key}=${encodeURIComponent(v)}`)
                            .join('&');
                    } else {
                        return `${key}=${encodeURIComponent(queryParams[key])}`;
                    }
                })
                .join('&');

            return `${ApiUrl}${url}?${queryParamsString}`;
        }
    }

    return `${ApiUrl}${url}`;
};

