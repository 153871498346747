import { Text, Image } from '@atoms/index';
import {
    getDecimalNumberWithComma,
    getStatusBadgeStyles,
    getPaymentStatus,
    getNumberAsCurrencyWithTwoFractionDigits,
} from '@helpers/miscelleanous';
import Refunds from '../Refunds';
import texts from './en.json';
import useWindowDimensions from '@hooks/useWindowDimensions';

const PaymentScheduleMobileView = ({
    viewInvestments,
    returns,
    paySchedule,
    totalAmount,
    payScheduleDateWithInterestObj,
    units,
    selectedPayment,
    setSelectedPayment,
    getTotalReturnReceived,
    setShowDetails,
    showDetails,
    showDetailsStatusMessage,
    showDetailsStatusArrow,
}) => {
    const { width } = useWindowDimensions();
    return (
        <div className="w-full px-4 flex flex-col mb-3 text-sm lg:hidden">
            <div className="overflow-auto min-w-[296px]">
                {!viewInvestments && (
                    <div className="grid bg-gray-100 py-3 px-2 gap-[6.6px] grid-cols-[18px_76px_1fr_80px] md:grid-cols-[1fr_1fr_1fr_1fr] content-center mb-2 rounded sticky -top-6">
                      <Text
                        content={texts.No}
                        className="text-left p5-semibold text-basicBlack"
                      />
                      <Text
                        content={texts.Date}
                        className="text-left p5-semibold text-basicBlack"
                      />
                      <Text
                        content={texts.Principal}
                        className="text-right p5-semibold text-basicBlack"
                      />
                      <Text
                        content={texts.Interest}
                        className="text-right p5-semibold text-basicBlack"
                      />
                  </div>
                )}

                {paySchedule.map((payment, index) => {
                    return (
                        <>
                            <div className="py-2 px-2 grid grid-cols-[18px_76px_1fr_80px] gap-[6.6px] md:grid-cols-[1fr_1fr_1fr_1fr] content-center auto-cols-min">
                                <Text
                                    content={index + 1}
                                    className="text-left text-gray-700 p5-regular self-center"
                                />
                                <Text
                                    content={payment.formattedDate}
                                    className="text-gray-700 p5-regular text-left self-center"
                                />
                                <Text
                                    content={getNumberAsCurrencyWithTwoFractionDigits(
                                        payment.totalPrincipal
                                    )}
                                    className={`text-gray-700 ${width >= 375 ? 'p4-regular' : 'p5-regular'} text-right`}
                                />
                                <Text
                                    content={getNumberAsCurrencyWithTwoFractionDigits(
                                        payment.totalInterest
                                    )}
                                    className={`text-gray-700 ${width >= 375 ? 'p4-regular' : 'p5-regular'} text-right`}
                                />
                                {/* </div> */}
                                {viewInvestments && (
                                    <div className="flex flex-col">
                                        <div
                                            className={`rounded px-2 py-1 self-end ${getStatusBadgeStyles(
                                                getPaymentStatus(payment)
                                            )}`}
                                        >
                                            <Text
                                                content={getPaymentStatus(
                                                    payment
                                                )}
                                                className="font-medium"
                                                size="sm"
                                            />
                                        </div>

                                        {payment.status !== 'Received' ? (
                                            <div className="text-center text-gray-400">
                                                -
                                            </div>
                                        ) : (
                                            <button
                                                className="pt-1 text-xs text-secondary-500"
                                                onClick={() => {
                                                    setSelectedPayment(payment);
                                                    setShowDetails(
                                                        !showDetails
                                                    );
                                                }}
                                            >
                                                <Text
                                                    content={showDetailsStatusMessage(
                                                        payment
                                                    )}
                                                    className="inline pr-1 2xl:pr-3"
                                                />
                                                <Image
                                                    src={showDetailsStatusArrow(
                                                        payment
                                                    )}
                                                    className="inline"
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* </div> */}
                            {selectedPayment === payment && showDetails && (
                                <div className="flex flex-col md:pb-5 text-xs border-t-2 border-gray-100 bg-gray-50">
                                    {payment.type === 'Refund' ? (
                                        <Refunds payment={payment} />
                                    ) : (
                                        <div className="flex flex-col p-2">
                                            <div className="flex items-center justify-between pb-4">
                                                <Text
                                                    content="Expected Amount"
                                                    className="text-gray-500"
                                                />
                                                <Text
                                                    content={
                                                        payment.type ===
                                                        'OutOfSchedule'
                                                            ? '-'
                                                            : `₹ ${getDecimalNumberWithComma(
                                                                  Number(
                                                                      units
                                                                  ) *
                                                                      payment.cashFlow,
                                                                  2
                                                              )}`
                                                    }
                                                    className="font-medium"
                                                />
                                            </div>
                                            <div className="flex items-center justify-between pb-4">
                                                <Text
                                                    content="Expected Date"
                                                    className="text-gray-500"
                                                />
                                                <Text
                                                    content={
                                                        payment.type ===
                                                        'OutOfSchedule'
                                                            ? '-'
                                                            : payment.date
                                                    }
                                                    className="font-medium"
                                                />
                                            </div>
                                            <div className="flex items-center justify-between pb-4">
                                                <Text
                                                    content="Total Gross Amount Received"
                                                    className="text-gray-500"
                                                />
                                                <Text
                                                    content={`₹ ${getDecimalNumberWithComma(
                                                        Number(units) *
                                                            payment.cashFlow,
                                                        2
                                                    )}`}
                                                    className="font-medium"
                                                />
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <Text
                                                    content="Received Date"
                                                    className="text-gray-500"
                                                />
                                                <Text
                                                    content={payment.date}
                                                    className="font-medium"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {payment.type === 'OutOfSchedule' && (
                                        <div className="border-t-2 border-gray-100 py-3 px-2">
                                            <div className="py-2 rounded-md font-semibold text-center bg-green-100 text-semantic-success-base ">
                                                Out Of Schedule
                                            </div>
                                        </div>
                                    )}
                                    {isInterest && (
                                        <div className="flex px-2 py-3 border border-t-2 border-gray-100">
                                            <Image
                                                src="/images/info-new.svg"
                                                className="pr-3"
                                            />
                                            <Text
                                                content="Note: The amount received in your bank account will be net of applicable TDS"
                                                className="text-gray-500"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                })}
            </div>

            <div
                className={`grid sticky left-0 ${
                    viewInvestments ? 'grid-cols-5' : 'grid-cols-3'
                } col-start-auto gap-2 px-2 py-3 text-sm bg-gray-100 rounded-b-lg`}
            >
                <div className="col-span-1">
                    <Text content="Total" className="p5-semibold break-all" />
                </div>
                <Text
                    content={`₹ ${getDecimalNumberWithComma(
                        Number(units) * totalAmount,
                        2
                    )}`}
                    className="font-medium col-span-2 break-all text-right"
                />
                {/* {viewInvestments && (
                    <Text
                        content={`₹ ${getDecimalNumberWithComma(
                            getTotalReturnReceived(),
                            2
                        )}`}
                        className="p4-semibold col-span-2 break-all text-right"
                    />
                )} */}
            </div>
        </div>
    );
};

export default PaymentScheduleMobileView;
