import Image from "@atoms/Image";
import { LazyImage } from "@ui/atoms";

const MediaCard = ({ data, index, shouldLinkWork }) => {
    return (
        <li key={index} className="list-none">
            <a {...(shouldLinkWork ? { href: data?.url } : {})} target="_blank">
                <LazyImage
                    src={`${data?.image}`}
                    className="m-auto relative w-[156px] h-10 xl:w-[190px] xl:h-12"
                    width={data?.mWidth}
                    height={data?.mHeight}
                    alt={data?.alt || ''}
                />
            </a>
        </li>
    );
};

export default MediaCard;