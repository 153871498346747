import React from 'react';
import { cn } from '../utils/utils';
import { AnimatePresence, motion } from 'framer-motion';

/**
 *
 * Usage example:
 *
 * ```jsx
 * import React, { useState } from 'react';
 * import { RadioGroup, RadioGroupItem } from './radio-group';
 *
 * const MyComponent = () => {
 *   const [selectedValue, setSelectedValue] = useState('option1');
 *
 *   return (
 *     <RadioGroup
 *       name="myRadioGroup"
 *       value={selectedValue}
 *       onChange={setSelectedValue}
 *       className="my-custom-class"
 *       error="Please select an option"
 *       errorClassName="custom-error-class"
 *     >
 *       <RadioGroupItem label="Option 1" value="option1" />
 *       <RadioGroupItem label="Option 2" value="option2" />
 *       <RadioGroupItem label="Option 3" value="option3" />
 *     </RadioGroup>
 *   );
 * };
 * ```
 *
 * Props for RadioGroup:
 * - `className`: Custom CSS class for additional styling.
 * - `name`: Name attribute for each radio input in the group, ensuring they are grouped correctly.
 * - `value`: The currently selected value within the group.
 * - `onChange`: Callback function that is called when the selected value changes.
 * - `children`: RadioGroupItem components or any valid React elements to render within the group.
 * - `error`: Error message to display if there is an error.
 * - `errorClassName`: Custom CSS class for the error message for additional styling.
 *
 * Props for RadioGroupItem:
 * - `className`: Custom CSS class for additional styling.
 * - `label`: Text label displayed next to the radio input.
 * - `value`: Value attribute for the radio input, used for determining if it's selected.
 * - `checkedLabelClass`: Custom CSS class for the label when the radio item is checked.
 * - `checked`: Boolean indicating if the radio item is checked.
 *
 * Note: Both components use the `forwardRef` API to allow for ref forwarding, and the `cn` utility function
 * for conditional class name concatenation. The RadioGroupItem can optionally render children when checked.
 */

const RadioGroup = React.forwardRef(
    ({ className, name, children, error, errorClassName, ...props }, ref) => (
        <div
            ref={ref}
            className={cn('flex flex-col gap-2', className)}
            {...props}
        >
            {children}
            {error && (
                <RadioErrorText message={error} className={errorClassName} />
            )}
        </div>
    )
);

RadioGroup.displayName = 'RadioGroup';

const RadioGroupContainer = React.forwardRef(
    (
        {
            className,
            name,
            value,
            onChange,
            children,
            error,
            errorClassName,
            ...props
        },
        ref
    ) => (
        <div
            className={cn(
                'flex items-center gap-3 md:gap-4 flex-wrap w-full',
                className
            )}
            {...props}
        >
            {React.Children.map(children, (child) => {
                return React.isValidElement(child)
                    ? React.cloneElement(child, {
                        name,
                        checked: child.props.value === value,
                        onChange: () => {
                            onChange(child.props.value);
                        },
                        error,
                    })
                    : child;
            })}
        </div>
    )
);

RadioGroupContainer.displayName = 'RadioGroupContainer';

const RadioLabel = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={cn('p4-regular text-gray-900', className)}
            {...props}
        />
    );
});
RadioLabel.displayName = 'RadioLabel';

const RadioGroupItem = React.forwardRef(
    (
        {
            className,
            label,
            checkedLabelClass = 'p5-semibold md:p4-semibold',
            unCheckedLabelClass = 'p5-regular md:p4-regular',
            checked,
            disabled = false,
            children,
            error,
            ...props
        },
        ref
    ) => (
        <motion.div
            className={cn(
                'relative flex gap-4 flex-col group ',
                { 'opacity-50': disabled },
                className
            )}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
        >
            <input
                id={props.id}
                type="radio"
                ref={ref}
                className="hidden"
                checked={checked}
                disabled={disabled}
                {...props}
            />
            <label
                htmlFor={props.id}
                className="flex items-center gap-2 cursor-pointer"
            >
                <motion.span
                    className={cn(
                        'w-5 h-5 p-1 m-[2px] transition-all ease-in-out rounded-full border flex items-center justify-center group-hover:border-primary-500',
                        {
                            'border-primary-500 bg-basicWhite': !disabled,
                            'border-gray-300 bg-gray-100': disabled,
                        }
                    )}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {checked && (
                        <span className={cn(
                            'block w-full h-full rounded-full bg-primary-500',
                            {
                                'bg-gray-300': disabled,
                            }
                        )}></span>
                    )}
                </motion.span>
                {label && (
                    <motion.span
                        className={cn(
                            'select-none whitespace-nowrap text-gray-600',
                            {
                                [checkedLabelClass]: checked,
                                [unCheckedLabelClass]: !checked,
                                'text-gray-400': disabled,
                                'text-gray-800': !disabled,
                            }
                        )}
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {label}
                    </motion.span>
                )}
            </label>
            {checked && children && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="w-full h-full mt-3"
                >
                    {children}
                </motion.div>
            )}
        </motion.div>
    )
);

RadioGroupItem.displayName = 'RadioGroupItem';

const RadioErrorText = ({ message, className }) => {
    const errorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            x: [0, -10, 10, -10, 10, 0],
            transition: { type: 'spring', stiffness: 300, duration: 0.3 },
        },
    };

    return (
        <AnimatePresence>
            {message && (
                <motion.p
                    className={cn(
                        'text-semantic-error-base p5-regular',
                        className
                    )}
                    variants={errorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    {message}
                </motion.p>
            )}
        </AnimatePresence>
    );
};

export {
    RadioGroup,
    RadioLabel,
    RadioGroupContainer,
    RadioGroupItem,
    RadioErrorText,
};