import { toast } from 'react-toastify';

import { getInvestorPanValidation } from '@services/investors.service';
import {
    isKycVerified,
    isKycVerifiedOrManualVerificationRequired,
    isManualVerificationRequired,
} from '@helpers/utils/investor-utils';
import KycStatusEnums from '@helpers/kyc-status';

export const getCurrentTab = (updatedKycNavItems, status) => {
    const currentTab = updatedKycNavItems.find((each) => !each.isCompleted);

    /*
         if investor is in manual verification and isBlockedInManualToSameStep is undefined/null than only return null for kyc manual verification page
         else return current active step
    */
    if (
        currentTab?.step === 4 &&
        isManualVerificationRequired(status) &&
        !currentTab?.isBlockedInManualToSameStep
    ) {
        return null;
    }

    if (currentTab) {
        return currentTab;
    } else {
        return null;
    }
};

export const getUpdatedKycNavItems = async (data, kycNavItems) => {
    const formattedData = Object.entries(data).map(([key, value]) => ({
        id: key,
        status: value,
    }));

    let isAadhaarVerified = false;

    // TODO: JO-1363 multiple api calls are happening
    const getInvestorPanValidationResponse = await getInvestorPanValidation();
    if (getInvestorPanValidationResponse?.status) {
        isAadhaarVerified = getInvestorPanValidationResponse.entity.some(
            ({ status, type }) =>
                isKycVerified(status) && type === 'AADHAAR_LINK'
        );
    }

    const getStepCompleteStatus = (statusItem, submenuItem) => {
        if (!statusItem) return;
        if (statusItem?.id === 'pan') return isAadhaarVerified;

        return (
            isKycVerified(statusItem?.status) ||
            (isManualVerificationRequired(statusItem.status) &&
                !submenuItem?.isBlockedInManualToSameStep)
        );
    };

    const result = kycNavItems.map((navItem) => {
        let inCompletedStep = null;
        const updatedSubmenu = navItem.submenu.map((submenuItem) => {
            const statusItem = formattedData.find(
                (item) => item.id === submenuItem.id
            );

            const record = {
                ...submenuItem,
                isCompleted: getStepCompleteStatus(statusItem, submenuItem),
                status: statusItem.status,
            };

            if (!inCompletedStep) {
                inCompletedStep = !record.isCompleted
                    ? {
                          id: submenuItem?.id,
                          isBlockedInManualToSameStep:
                              record?.isBlockedInManualToSameStep || null,
                      }
                    : null;
            }

            return record;
        });

        const isCompleted = updatedSubmenu.every(
            (submenuItem) => submenuItem.isCompleted
        );

        const manualVerificationRequired = updatedSubmenu.some((submenuItem) =>
            isManualVerificationRequired(submenuItem.status)
        );
        const status = manualVerificationRequired
            ? KycStatusEnums.MANUAL_VERIFICATION_REQUIRED
            : isCompleted
            ? KycStatusEnums.VERIFIED
            : KycStatusEnums.PENDING;

        return {
            ...navItem,
            isBlockedInManualToSameStep:
                inCompletedStep?.isBlockedInManualToSameStep || null,
            submenu: updatedSubmenu,
            isCompleted,
            status,
        };
    });

    const isCompleted = result.every((submenuItem) => submenuItem.isCompleted);
    const manualVerificationRequired = result.some((submenuItem) =>
        isManualVerificationRequired(submenuItem.status)
    );

    const status = isCompleted
        ? KycStatusEnums.VERIFIED
        : manualVerificationRequired
        ? KycStatusEnums.MANUAL_VERIFICATION_REQUIRED
        : KycStatusEnums.PENDING;

    return { result, status };
};

export const displayToast = (message, position = 'bottom-center') => {
    toast.dismiss();
    toast.error(message, {
        position,
        progress: null,
        style: {
            '--toastify-color-progress-info': '#0F5299',
            '--toastify-icon-color-info': '#0F5299',
        },
        autoClose: 5000,
    });
};
