import StructuredData from '@components/StructuredData';
import AuthContext from '@context/auth';
import { homePageStructuredData } from '@helpers/constants';
import { HeadTagData } from '@helpers/headTagData';
import { getQueryParamsFromUrl } from '@helpers/miscelleanous';
import BottomNavigation from '@molecules/BottomNavigation';
import { getHomePageConfigurations, getOpenConfigurations } from '@services/configuration.service';
import { getAuth, setReferralCode, setReferralEntityType } from '@services/identity.service';
import useAuthStore from '@store/useAuthStore';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import { useWindowSize } from '@uidotdev/usehooks';
import 'animate.css/animate.min.css';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import {logEvent, moengageEventConstants} from "@services/moengage";
import useHomePageDeal from "@components/Homepage/OpportunitiesCarousel/hooks/useHomePageDeal";
import { getHomePageDisplayDeals } from '@services/homepage-deals-service';
import { isValidResponseEntity } from '@helpers/utils';
import { formatHomePageDeals } from '@components/Homepage/OpportunitiesCarousel/utils/helpers';
import { configurationEntityTypes } from '@helpers/enums/configuration-enums';
import moment from 'moment';
import BondDirectoryBanner from '@components/Homepage/BondDirectoryBanner';
const FestiveBanner = dynamic(() =>
    import('@components/Homepage/Banner/FestiveBanner')
);
const Banner = dynamic(() => import('@components/Homepage/Banner'));
const ImageBanner = dynamic(() => import('@components/Homepage/ImageBanner'));
const WhyInvest = dynamic(() => import('@components/Homepage/WhyInvest'));
const Products = dynamic(() => import('@components/Homepage/Products'));
const InvestmentOptions = dynamic(() => import('@components/Homepage/InvestmentOptions'));
const FinancialProsperity = dynamic(() => import('@components/Homepage/FinancialProsperity'));
const StartInvesting = dynamic(() => import('@components/Homepage/StartInvesting'));
const Investors = dynamic(() => import('@components/Homepage/Investors'));
const RegulatedFramework = dynamic(() => import('@components/Homepage/RegulatedFramework'));
const InvestmentJourney = dynamic(() => import('@components/Homepage/InvestmentJourney'));
const MediaCoverage = dynamic(() =>
    import('@components/Homepage/MediaCoverage')
);
const OpportunitiesCarousel = dynamic(() =>
    import('@components/Homepage/OpportunitiesCarousel')
);
const NavHeader = dynamic(() => import('@molecules/NavHeader'), {
    ssr: true,
});
const Footer = dynamic(() => import('@molecules/Footer'), {
    ssr: false,
});


const amoBannerConfigValue = 'BannerVersion 2.0';
const christmasConfigValue = "ChristmasBanner";

function Homepage({ configuration, homepageDealsResponse, muhuratExpiry }) {
    const {
        signUpModal,
        loginModal,
        investor,
        rmDetails,
        showRMDetails,
        bondFeatureFlag,
    } = useContext(AuthContext);
    const { isLogged } = useAuthStore();
    const auth = getAuth();
    const router = useRouter();
    const [isBottomNavVisible, setIsBottomNavVisible] = useState(false);
    const { width } = useWindowSize();
    const {
        homePageDeals,
        error: homePageDealsError,
    } = useHomePageDeal({
        homepageDealsResponse
    })
    const showMuhuratDays = moment(muhuratExpiry).isValid() ? moment().isSameOrBefore(moment(muhuratExpiry)) : false;

    const handleButtonClick = () => {
        if (isLogged) {
            router.push('/opportunities');
        } else {
            signUpModal();
        }
    };
    useEffect(() => {
        logEvent({eventName: moengageEventConstants.VIEWED_HOME_PAGE,});
    }, []);

    useEffect(() => {
        const retrieveReferralCode = () => {
            const referralCode =
                router.query?.referrer ||
                router.query?.usoc ||
                getQueryParamsFromUrl(window.location.search, 'usoc') ||
                getQueryParamsFromUrl(window.location.search, 'referrer');

            if (referralCode) {
                if (
                    router.query?.usoc ||
                    getQueryParamsFromUrl(window.location.search, 'usoc')
                ) {
                    setReferralEntityType('usoc');
                } else {
                    setReferralEntityType('referrer');
                }
                setReferralCode(referralCode);
            }
        };

        retrieveReferralCode();
    }, []);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const animationClass = entry.target.dataset.animation;
                    if (animationClass) {
                        entry.target.classList.add(
                            'animate__animated',
                            animationClass
                        );
                    }
                }
            });
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver(
            handleIntersection,
            observerOptions
        );

        const targets = document.querySelectorAll('[data-animation]');
        targets.forEach((target) => {
            target.classList.add('animatable');
            observer.observe(target);
        });

        return () => {
            targets.forEach((target) => {
                observer.unobserve(target);
            });
        };
    }, []);

    useEffect(() => {
        // if redirect and auth both are present then navigate to the given url else open loginPopup in case only redirect is present
        let redirect = router?.query?.redirect || '';
        if (auth && redirect) {
            router.replace(redirect);
        } else if (redirect) {
            loginModal();
        }
    }, [router?.query]);

    return (
        <>
            <NextHeadTag {...HeadTagData['/']} />
            <StructuredData data={homePageStructuredData} />
            <div className={`bg-bgColor-50 ${isBottomNavVisible ? 'pb-[70px] xl:pb-0' : 'pb-0'}`}>
                <div className="overflow-hidden homepage-wrapper" id="pagesindex">
                    <NavHeader
                        hideHeaderOnScroll={true}
                        translucent={false}
                        homePage={true}
                    />
                    <div id="homepageWrapper" className='overflow-hidden'>
                        {configuration?.value === amoBannerConfigValue ? (
                            <ImageBanner
                                config={amoBannerConfigValue}
                                onClick={() => {
                                    if (isLogged) {
                                        router.push('/opportunities');
                                    } else {
                                        loginModal();
                                    }
                                }}
                            />
                        ) : configuration?.value === christmasConfigValue ? (
                            <FestiveBanner onButtonClick={handleButtonClick} />
                        ) : (
                            <Banner
                                onButtonClick={handleButtonClick}
                                showMuhuratDays={showMuhuratDays}
                            />
                        )}
                        {bondFeatureFlag && (
                            <BondDirectoryBanner
                                className={showMuhuratDays ? 'mt-16' : 'mt-0'}
                            />
                        )}
                        <div className='bg-[url(/images/homepage/background-gradient-2.svg)] relative z-20 bg-cover bg-top bg-no-repeat'>
                            {homePageDeals.length > 0 && !homePageDealsError ? (
                                <OpportunitiesCarousel
                                    signUpModal={signUpModal}
                                    isLogged={isLogged}
                                    opportunities={homePageDeals}
                                    className={"md:mt-20"}
                                />
                            ) : null}
                            {/* Uncomment to display recent deals */}
                            {/* <RecentDeals onButtonClick={signUpModal} /> */}
                            <WhyInvest titleTag='h1' />
                            <Products onButtonClick={handleButtonClick} />
                        </div>
                        <InvestmentOptions />
                        <div className='bg-basicWhite bg-[url(/images/homepage/background-gradient-3.svg)] bg-top bg-cover bg-no-repeat'>
                            <FinancialProsperity />
                            <StartInvesting onButtonClick={handleButtonClick} />
                            <RegulatedFramework />
                            <MediaCoverage />
                            <Investors />
                            <InvestmentJourney
                                onButtonClick={handleButtonClick}
                            />
                        </div>
                    </div>
                    <Footer
                        isLightFooter={false}
                        entityType={auth?.entityType}
                    />
                </div>
            </div>
            {width && width < 1280 ? <BottomNavigation investor={investor} visible={true} rmDetails={rmDetails} setIsBottomNavVisible={setIsBottomNavVisible} showRMDetails={showRMDetails} /> : null}
        </>
    );
}

function supportsIntersectionObserver() {
    return !!(
        'IntersectionObserver' in window ||
        'IntersectionObserverEntry' in window ||
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
    );
}

export default Homepage;

export const getStaticProps = async (ctx) => {
    const defaultConfig = { type: 'General', entityType: 'HomePageBanner', value: 'Default' };
    const HOME_PAGE_REVALIDATION_CACHE_TIME = parseInt(process.env.NEXT_PUBLIC_HOME_PAGE_REVALIDATION_TIME);
    try {
        // BANNER CONFIG
        const response = await getHomePageConfigurations(ctx);
        // HOME PAGE DEALS
        const homepageDealsResponse = await getHomePageDisplayDeals(ctx)
        const formattedDeals = {
            ...homepageDealsResponse,
            entity: isValidResponseEntity(homepageDealsResponse) ? formatHomePageDeals(homepageDealsResponse?.entity) : [],
        };
        // MuhuratDays
        const muhuratDaysResponse = await getOpenConfigurations(configurationEntityTypes.MuhuratDays, ctx);
        const muhuratExpiry = (isValidResponseEntity(muhuratDaysResponse) && muhuratDaysResponse?.entity?.length) ? muhuratDaysResponse?.entity?.[0]?.value : null;

        return {
            props: {
                configuration: (!isValidResponseEntity(response) || response?.entity?.length === 0) ? defaultConfig : response?.entity?.[0],
                homepageDealsResponse: formattedDeals,
                muhuratExpiry: muhuratExpiry
            },
            revalidate: HOME_PAGE_REVALIDATION_CACHE_TIME,
        };
    } catch (error) {
        return {
            props: { configuration: defaultConfig, homepageDealsResponse: {entity: [], status: false, message: 'Something went wrong' }},
            revalidate: HOME_PAGE_REVALIDATION_CACHE_TIME,
        };
    }
};
