import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import { mediaCoverages } from "@helpers/homepageData";
import MediaCard from "./MediaCard";
import VisibilitySensor from "react-visibility-sensor";

const MediaCoverage = ({
    shouldLinkWork = true
}) => {
    return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
                <div className="max-width-wrapper py-12 md:py-16 xl:py-18 xl:px-16 flex flex-col items-center">
                    <Text content={texts?.MediaCoverage} className="h4-semibold md:h3-semibold xl:h2-semibold text-primary-500" htmlTag="h2" />
                    <Text content={texts?.MediaSays} className="mt-2 text-basicBlack p3-regular" />
                    <ul className="grid gap-4 lg:gap-y-8 grid-cols-2 md:grid-cols-4 xl:grid-cols-6 mt-2 xl:mt-4 flex-row flex-nowrap gap-x-4 lg:gap-x-8 items-center p-4 md:max-w-full max-w-[440px] mx-auto w-full">
                        {mediaCoverages?.map((data, index) => (
                            <MediaCard key={index} data={data} index={index} shouldLinkWork={shouldLinkWork} />
                        ))}
                    </ul>
                </div >)}
        </VisibilitySensor>
    );
};

export default MediaCoverage;