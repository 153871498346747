const updateQueryParams = (currentQuery, paramsToAdd, paramsToRemove = []) => {
    const queryParams = new URLSearchParams(currentQuery);

    paramsToRemove.forEach((param) => {
        queryParams.delete(param);
    });

    Object.entries(paramsToAdd).forEach(([key, value]) => {
        queryParams.delete(key);

        if (Array.isArray(value)) {
            queryParams.set(key, value.join(','));
        } else if (typeof value === 'boolean') {
            queryParams.set(key, value.toString());
        } else if (typeof value === 'number') {
            queryParams.set(key, value);
        } else if (value) {
            queryParams.set(key, value);
        }
    });

    return queryParams.toString();
};

export { updateQueryParams };
