import { getNumberWithComma } from '@helpers/miscelleanous';
import {
    CONSTANTS,
    customMinInvestmentSteps,
    FILTER_OPTIONS,
    VALID_KEYS,
} from './helper';
import { opportunitiesFilterTypes } from '@helpers/enums/opportunities-filter-types';
import { getShortNumber } from '@ui/helpers/miscelleanous';

const getAppliedFilters = (queryParams) => {
    const chips = [];

    // Yield
    if (queryParams.yield) {
        const yieldValues = queryParams.yield.split(',');
        yieldValues.forEach((yieldValue) => {
            const value = FILTER_OPTIONS.yield.find(
                (option) => option.value === yieldValue
            );

            chips.push({
                key: 'yield',
                label: `Yield: ${value?.label}`,
                value: yieldValue,
            });
        });
    }

    // Tenure
    if (queryParams.tenure) {
        const tenureValues = queryParams.tenure.split(',');
        tenureValues.forEach((tenureValue) => {
            const value = FILTER_OPTIONS.tenure.find(
                (option) => option.value === tenureValue
            );
            chips.push({
                key: 'tenure',
                label: `Tenure: ${value.label}`,
                value: tenureValue,
            });
        });
    }

    // Min Investment
    if (queryParams.amount) {
        let min, max, formattedMin, formattedMax;

        if (queryParams.amount.includes('-')) {
            // Handle when `amount` is a range in string format (e.g., "0-5000")
            [min, max] = queryParams.amount.split('-').map(Number);
        } else if (queryParams.amount.startsWith('>=')) {
            // Handle when `amount` is a single minimum value (e.g., ">=1000")
            min = Number(queryParams.amount.replace('>=', ''));
            max = null; // No maximum
        }

        formattedMin = min !== undefined ? `₹${getNumberWithComma(min)}` : '';
        formattedMax =
            max === null
                ? ''
                : max === 100000
                ? '₹1,00,000+'
                : `₹${getNumberWithComma(max)}`;

        const label =
            min === 0 && max !== null
                ? `Min Investment: Upto ${formattedMax}`
                : max === null
                ? `Min Investment: > ${formattedMin}`
                : `Min Investment: ${formattedMin} - ${formattedMax}`;

        chips.push({
            key: 'amount',
            label: label,
            value: queryParams.amount,
        });
    }

    // Rating
    if (queryParams.rating) {
        const ratingValues = queryParams.rating.split(',');

        ratingValues.forEach((ratingValue) => {
            const value = FILTER_OPTIONS.rating.find(
                (option) => option.value === ratingValue
            );
            chips.push({
                key: 'rating',
                label: `Rating: ${value.label}`,
                value: ratingValue,
            });
        });
    }

    // Secured Opportunities
    if (queryParams.secured) {
        chips.push({
            label: 'Secured',
            value: queryParams.secured,
            key: 'secured',
        });
    }

    return { chips, count: chips.length };
};

const getActiveTabType = (filter, isUnlistedSDI) => {
    switch (filter) {
        case opportunitiesFilterTypes.BOND:
            return 'bond';
        case opportunitiesFilterTypes.SDI:
            return isUnlistedSDI
                ? 'UnlistedSecuritisedDebtInstrument'
                : 'SecuritisedDebtInstrument';
        case opportunitiesFilterTypes.T_BILL:
            return 'TreasuryBill';
        default:
            return '';
    }
};

const filterValidQueryParams = (query) => {
    const updatedQuery = {};

    for (const key in query) {
        if (VALID_KEYS[key]) {
            const values = query[key].split(',');
            const validValues = values.filter((value) =>
                VALID_KEYS[key].includes(value)
            );

            if (validValues.length) {
                updatedQuery[key] = validValues.join(',');
            }
        } else if (key === 'type') {
            if (query[key] === opportunitiesFilterTypes.BOND) {
                updatedQuery[key] = opportunitiesFilterTypes.BOND;
            } else if (query[key] === opportunitiesFilterTypes.SDI) {
                updatedQuery[key] = opportunitiesFilterTypes.SDI;
            } else if (query[key] === opportunitiesFilterTypes.T_BILL) {
                updatedQuery[key] = opportunitiesFilterTypes.T_BILL;
            }
        } else if (key === 'pageNo' || key === 'limit' || key === 'status') {
            updatedQuery[key] = query[key];
        }
    }

    return updatedQuery;
};

const getRatingHelperText = (selectedRatings) => {
    if (selectedRatings.length === 0) {
        return false;
    }

     const ratingRanges = {
         high: 'AAA to AA-',
         medium: 'A+ to BBB+',
         low: 'BBB to D',
     };

     if (selectedRatings.length === 1) {
         const rating = selectedRatings[0];
         if (rating === 'high') {
             return 'Ratings from AAA to AA- fall under the High rating category.';
         }
         if (rating === 'medium') {
             return 'Ratings from A+ to BBB+ are considered Medium rating.';
         }
         if (rating === 'low') {
             return 'Ratings from BBB to D are categorized as Low rating.';
         }
     }

    if (selectedRatings.length > 1) {
        const sortedRatings = selectedRatings.sort((a, b) => {
            const order = ['low', 'medium', 'high'];
            return order.indexOf(a) - order.indexOf(b);
        });

        const minRange = ratingRanges[sortedRatings[0]];
        const maxRange = ratingRanges[sortedRatings[sortedRatings.length - 1]];

        if (selectedRatings[0] === 'low' && selectedRatings[1] === 'high') {
            return 'Rating from AAA to AA- & BBB to D fall under the selected categories.';
        }

        return `Ratings from ${maxRange.split(' ')[0]} to ${
            minRange.split(' ')[2]
        } fall under the selected categories.`;
     }

    return false;
};

const parseQueryValue = (value) => (value ? value.split(',') : []);
const parseAmount = (amount) => {
    if (!amount) return CONSTANTS.MIN_MAX_AMOUNT;

    if (amount.includes('-')) {
        // Format like "25000-75000"
        return amount.split('-').map((item) => parseInt(item, 10));
    } else if (amount.startsWith('>=')) {
        //For  format like ">=25000"
        const min = parseInt(amount.replace('>=', ''), 10);
        return [min, CONSTANTS.MIN_MAX_AMOUNT[1]];
    }

    return CONSTANTS.MIN_MAX_AMOUNT;
};

const labelFormatter = (value) => {
    if (value === 0) return '< 1K';
    if (value === customMinInvestmentSteps[customMinInvestmentSteps.length - 1])
        return '1Lakh +';
    return `₹${getShortNumber(value)}`;
};

export {
    getAppliedFilters,
    getActiveTabType,
    filterValidQueryParams,
    getRatingHelperText,
    parseQueryValue,
    parseAmount,
    labelFormatter,
};
