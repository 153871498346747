import React, { useRef, useCallback } from 'react';
import { XIcon } from '@heroicons/react/outline';

import { Text } from '@ui/atoms';
import { cn } from '@ui/atoms/utils/utils';

import { useOutsideClick } from '@ui/atoms/hooks';
import useToggleBodyOverflow from '@ui/hooks/useToggleBodyOverflow';

const BottomDrawer = ({
    visible,
    title,
    titleClassName = '',
    children,
    wrapperClass = '',
    textWrapperClass = '',
    closeButtonLabel = 'Close',
    onClose,
    ...props
}) => {
    const ref = useRef(null);

    useToggleBodyOverflow(visible);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    // Outside click listener to close the drawer
    useOutsideClick(ref, handleClose);

    return (
        <div
            className={cn(
                'fixed bottom-0 left-0 w-full h-screen bg-basicBlack/40 z-999',
                visible ? 'flex' : 'hidden'
            )}
        >
            <div
                ref={ref}
                className={cn(
                    'bg-basicWhite shadow-lg border border-primary-10 rounded-t-2xl left-0  scroll-vertical-width-0 fixed w-full bottom-0 flex-col animate__slideInUp animate__animated max-h-[75%]',
                    wrapperClass
                )}
                {...props}
            >
                <div
                    className={cn(
                        'sticky top-0 flex items-center justify-center px-4 py-5 border-b border-gray-200 bg-basicWhite',
                        textWrapperClass
                    )}
                >
                    <Text
                        content={title}
                        className={cn(
                            'text-basicBlack',
                            titleClassName || 'h5-semibold'
                        )}
                    />
                    <button
                        className="absolute cursor-pointer top-4 right-4"
                        onClick={handleClose}
                        aria-label={closeButtonLabel}
                    >
                        <XIcon className="w-6 h-6 text-gray-600" />
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
};
 
export default BottomDrawer;