import * as yup from 'yup';
import { CONSTANTS } from './helper';

const sortSchema = (defaultValues) =>
    yup.object().shape({
        bondSort: yup.string().default(defaultValues?.bondSort),
    });

const filterSchema = yup.object({
    yield: yup.array().of(yup.string()).default([]),
    tenure: yup.array().of(yup.string()).default([]),
    amount: yup.array().of(yup.string()).default(CONSTANTS.MIN_MAX_AMOUNT),
    rating: yup.array().of(yup.string()).default([]),
    secured: yup.boolean().default(false),
});
export { sortSchema, filterSchema };
