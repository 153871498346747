import Moment from 'moment';
import OpportunityTypes from './opportunity-types';
import { ZERO_COUPON_BOND } from './constants';

export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const parseData = (data) => {
    return JSON.parse(data);
};

export const mailToGmail = (value) =>
    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberWithComma = (number) => {
    return number && number.toLocaleString('en-IN');
};

export const scrollToTargetAdjusted = (reference, headerOffset = 64) => {
    let elementPosition = reference.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInitials = (fullName) => {
    let names = fullName.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getNumberInLakhAndCrore = (value) => {
    const updatedValue = String(value).slice(0, 4);
    if (value >= 100000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Cr`;
    }
    if (value < 100000000 && value >= 10000000) {
        const valueToShow =
            updatedValue[1] === '0' && updatedValue[2] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000 && value >= 1000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Lakh(s)`;
    }
    if (value < 1000000 && value >= 100000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Lakh(s)`;
    }
    return getNumberWithComma(value);
};

export const amountToBeMultipleOf = (amount, investmentMultiple) =>
    amount % investmentMultiple === 0;

export const isAssetBackedLeasingOpportunity = (opportunityType) => {
    return opportunityType === 'AssetBackedLeasing';
};

export const getDecimalNumberWithComma = (number, decimalPlace = 4) => {
    number = parseFloat(number).toFixed(decimalPlace);
    return number.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};

export const formatDate = (date, format = 'MMM D, YYYY') =>
    Moment.utc(date).utcOffset('+05:30').format(format);

export const downloadUrl = async (url) => {
    var link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const getNumberWithFourdecimal = (number) => {
    if (typeof number === 'string') {
        return Number(number).toFixed(4);
    }
    return number.toFixed(4);
};

export const stringifyData = (data) => {
    return JSON.stringify(data);
};

export const startTimer = (expiry, setMins, setSecs) => {
    let myInterval = setInterval(() => {
        let diff = Math.abs(expiry - new Date().getTime()) / 1000;
        setMins(String(Math.floor(diff / 60) % 60).padStart(2, '0'));
        setSecs(String(Math.floor(diff % 60)).padStart(2, '0'));
    }, 1000);
    return () => {
        clearInterval(myInterval);
    };
};

export const getMobileWithoutCountryCode = (mobile, countryCode) => {
    return mobile.replace(countryCode?.substring(1), '');
};

export const getQueryParamsFromUrl = (search, query) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(query);
};

export const isUndefined = (value) => {
    return value === undefined;
};

export const getFormattedPhoneNumber = (number) => {
    const countryCode = number.slice(0, 2);
    const phoneNumber = number.slice(2);

    return `+${countryCode} ${phoneNumber}`;
};

export const getInvestorStatusStyles = (status) => {
    switch (status) {
        case 'ManualVerificationRequired':
            return 'bg-semantic-warning-light text-semantic-warning-base';
        case 'Verified':
            return 'bg-semantic-success-light text-semantic-success-base';
        case 'Rejected':
            return 'bg-semantic-error-light text-semantic-error-base';
        default:
            return 'bg-semantic-info-light text-semantic-info-base';
    }
};

export const getSheetData = (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map((row) => {
        return fields.map((fieldName) => {
            return row[fieldName] ? row[fieldName] : '';
        });
    });
    sheetData.unshift(header);
    return sheetData;
};

export const isCorporateDebtOpportunity = (opportunityType) => {
    return opportunityType === OpportunityTypes.CORPORATE_BOND;
};

export const isVentureDebtOpportunity = (opportunityType) => {
    return opportunityType === 'VentureDebt';
};

export const isTreasuryBillOpportunity = (opportunityType) => {
    return opportunityType === OpportunityTypes.TREASURY_BILL;
};

// output time in 00:00 format
export const formatTimeForTimer = (timeInSeconds) => {
    var minutes = Math.floor(timeInSeconds / 60);
    var remainingSeconds = timeInSeconds % 60;

    // Add leading zeros if necessary
    var formattedMinutes = String(minutes).padStart(2, '0');
    var formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return formattedMinutes + ':' + formattedSeconds;
}

export const isZeroCouponBond = (displayAssetClass) => {
    return displayAssetClass == ZERO_COUPON_BOND
}

/**
 * Converts a number to a short form representation.
 * For example, 100000 becomes '1Lakh', 1000 becomes '1k', etc.
 * @param {number} num - The number to convert.
 * @returns {string} - The short form representation of the number.
 */
export const getShortNumber = (num) => {
    if (num >= 10000000) {
        return (num / 10000000).toFixed(0) + " " + 'Cr';
    } else if (num >= 100000) {
        return (num / 100000).toFixed(0) + ' ' + 'Lakh';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(0) + ' ' + 'k';
    }
    return num.toString();
};