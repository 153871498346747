'use client';

import { getAuth } from '@services/identity.service';
import { isProtected } from './Security';
import useAuthChange from 'hooks/useAuthChange';
import { onAuthChange } from '@helpers/miscelleanous';
import { useRouter } from 'next/router';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ children, isError }) => {
    const router = useRouter();
    useAuthChange((token) => onAuthChange(token));
    const auth = getAuth();
    const isAuthenticated = () => auth != null && auth?.exp;

    if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname) && !isError) {
        router.replace('/');
    }
    return children;
};

export default WithAuth;
