import { opportunitiesFilterTypes } from '@helpers/enums/opportunities-filter-types';
import { Text } from '@ui/atoms';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { filterValidQueryParams } from './utils/utils';

const opacity = {
    hidden: {
        opacity: 0,
        zIndex: -100,
        x: -100,
    },
    visible: {
        opacity: 1,
        zIndex: 5,
        transition: {
            duration: 0.1,
            x: 0,
        },
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.1,
        },
    },
};

const OpportunityTabs = ({
    triggerGaEventsForOpportunitiesTabs,
    tabs,
    regulationTabs,
    isUnlistedSDI,
}) => {
    const { width } = useWindowDimensions();

    const router = useRouter();
    const searchParams = useSearchParams();

    const filterParams =
        filterValidQueryParams({
            type: searchParams.get('type'),
        }).type || 'Bonds';

    function getActiveTabType(filter, isUnlistedSDI) {
        if (filter === opportunitiesFilterTypes.BOND) {
            return 'bond';
        } else if (filter === opportunitiesFilterTypes.SDI) {
            return isUnlistedSDI
                ? 'UnlistedSecuritisedDebtInstrument'
                : 'SecuritisedDebtInstrument';
        } else if (filter === opportunitiesFilterTypes.T_BILL) {
            return 'TreasuryBill';
        }
    }

    const onTabChange = (tab) => {
        const activeTab = getActiveTabType(tab, isUnlistedSDI);

        if (tab === filterParams) return;

        const params = new URLSearchParams();
        params.set('type', tab);

        if (
            activeTab ===
            getActiveTabType(opportunitiesFilterTypes.SDI, isUnlistedSDI)
        ) {
            params.set('regulation', isUnlistedSDI ? 'rbi' : 'sebi');
        } else {
            params.delete('regulation');
        }

        router.push(
            {
                query: Object.fromEntries(params.entries()),
            },
            undefined,
            { shallow: true }
        );
    };

    const onRegulationTabChange = (regulation) => {
        const params = new URLSearchParams(searchParams.toString());
        params.set('regulation', regulation);

        router.push(
            {
                query: Object.fromEntries(params.entries()),
            },
            undefined,
            { shallow: true }
        );
    };

    return (
        <nav>
            {width > 768 ? (
                <div className="flex flex-col gap-2">
                    <div className="inline-flex items-start justify-start p-1 overflow-hidden bg-white border border-gray-300 rounded-3xl">
                        {tabs.map((tab) => {
                            const { id, value } = tab;

                            const isActive = id === filterParams;

                            return (
                                <li
                                    key={id}
                                    className={`list-none flex items-center cursor-pointer px-6 py-2 relative`}
                                    onClick={() => {
                                        onTabChange(id);
                                        triggerGaEventsForOpportunitiesTabs(
                                            value
                                        );
                                    }}
                                >
                                    <Text
                                        content={value}
                                        className={`relative z-10  ${
                                            isActive
                                                ? 'text-basicWhite p4-medium'
                                                : 'text-primary-500 p4-semibold'
                                        }`}
                                    />
                                    <div className="relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70">
                                        <Text
                                            content={tab.count}
                                            className="p7-medium text-primary-500"
                                        />
                                    </div>
                                    {isActive && (
                                        <motion.div
                                            className="absolute top-0 rounded-full bottom-0 right-0 left-0 -w-full h-full z-[5] bg-primary-500"
                                            layoutId="underline"
                                            variants={opacity}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                        />
                                    )}
                                </li>
                            );
                        })}
                    </div>
                    {filterParams === opportunitiesFilterTypes.SDI && (
                        <motion.div
                            key={'listing-tabs'}
                            className={`inline-flex items-start justify-start overflow-hidden`}
                        >
                            {regulationTabs.map((tab) => {
                                const { id, value } = tab;
                                const isSelected =
                                    (isUnlistedSDI && id === 'rbi') ||
                                    (!isUnlistedSDI && id === 'sebi');
                                return (
                                    <li
                                        key={id}
                                        className={`list-none cursor-pointer flex items-center py-[10px] pr-[10px] pl-[14px]
                                                ${
                                                    isSelected
                                                        ? 'border-b-2 border-primary-500'
                                                        : 'border-none'
                                                }`}
                                        onClick={() => {
                                            onRegulationTabChange(id);
                                            tab.onClick();
                                        }}
                                    >
                                        <Text
                                            content={value}
                                            className={`relative z-10  ${
                                                isSelected
                                                    ? 'text-primary-500 p4-regular'
                                                    : ' text-gray-500 p4-medium'
                                            }`}
                                        />
                                        <div className="relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70">
                                            <Text
                                                content={tab.count}
                                                className="p7-medium text-primary-500"
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </motion.div>
                    )}
                </div>
            ) : (
                <div className="w-full space-y-4">
                    <div className="relative inline-flex items-center justify-between w-full overflow-hidden bg-white border-b-2 border-gray-200">
                        {tabs.map((tab) => {
                            const { id, value } = tab;
                            const activeId = getActiveTabType(
                                id,
                                isUnlistedSDI
                            );

                            const isActive = id === filterParams;

                            return (
                                <li
                                    key={id}
                                    className={`list-none flex flex-1 items-center cursor-pointer px-6 py-[10px]
                                            ${
                                                isActive
                                                    ? 'text-primary-500 p4-medium border-b-2 border-primary-500'
                                                    : 'text-gray-500 p4-regular'
                                            }`}
                                    onClick={() => {
                                        onTabChange(id);
                                        triggerGaEventsForOpportunitiesTabs(
                                            value
                                        );
                                    }}
                                >
                                    <Text content={value} />
                                    <div className="relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70">
                                        <Text
                                            content={tab.count}
                                            className="p7-medium text-primary-500"
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </div>
                    {filterParams === opportunitiesFilterTypes.SDI && (
                        <div
                            key={'listing-tabs-mobile'}
                            className="inline-flex items-start justify-center w-full px-4 overflow-hidden gap-x-3"
                        >
                            {regulationTabs.map((tab) => {
                                const { id, value } = tab;
                                const isSelected =
                                    (isUnlistedSDI && id === 'rbi') ||
                                    (!isUnlistedSDI && id === 'sebi');
                                return (
                                    <li
                                        key={id}
                                        className={`list-none cursor-pointer flex items-center py-2 pr-2 pl-3 gap-x-2 rounded-md border ${
                                            isSelected
                                                ? 'bg-primary-50 border-primary-70'
                                                : 'bg-basicWhite border-gray-200'
                                        }`}
                                        onClick={tab.onClick}
                                    >
                                        <Text
                                            content={value}
                                            className={`relative z-10  ${
                                                isSelected
                                                    ? ' text-primary-500 p5-medium'
                                                    : 'text-gray-500 p5-regular'
                                            }`}
                                        />
                                        <div className="relative flex items-center justify-center w-5 h-5 rounded-full bg-primary-70">
                                            <Text
                                                content={tab.count}
                                                className="p7-medium text-primary-500"
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </nav>
    );
};

export default OpportunityTabs;
