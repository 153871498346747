import { forwardRef } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@ui/atoms/utils/utils';
import Image from '@ui/atoms/Image';

const sizeClasses = {
    small: {
        root: 'h-5 w-9',
        thumb: 'h-4 w-4 data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
    },
    medium: {
        root: 'h-7 w-12',
        thumb: 'h-6 w-6 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
    },
    large: {
        root: 'h-9 w-16',
        thumb: 'h-8 w-8 data-[state=checked]:translate-x-7 data-[state=unchecked]:translate-x-0',
    },
};

const Switch = forwardRef(
    (
        {
            size = 'medium',
            disabled = false,
            checked,
            onChange,
            className,
            thumbClassName,
            thumbContent,
            ...props
        },
        ref
    ) => {
        const { root, thumb } = sizeClasses[size] || sizeClasses.medium;

        return (
            <SwitchPrimitives.Root
                className={cn(
                    `peer inline-flex shrink-0 px-0.5  cursor-pointer items-center rounded-full border-0 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 focus-visible:ring-offset-transparent disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-semantic-success-base data-[state=unchecked]:bg-primary-70`,
                    root,
                    className
                )}
                disabled={disabled}
                checked={checked}
                onCheckedChange={onChange}
                ref={ref}
                {...props}
            >
                <SwitchPrimitives.Thumb
                    className={cn(
                        `pointer-events-none  rounded-full bg-basicWhite shadow-lg ring-0 transition-transform  flex items-center justify-center`,
                        thumb,
                        thumbClassName
                    )}
                >
                    {thumbContent ||
                        (checked && (
                            <Image
                                src="/icons/CheckSuccess.svg"
                                alt="toggle"
                                width={16}
                                height={16}
                            />
                        ))}
                </SwitchPrimitives.Thumb>
            </SwitchPrimitives.Root>
        );
    }
);

Switch.displayName = 'Switch';

export { Switch };
