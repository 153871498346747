import { useEffect } from 'react';

const useKeyboardNavigation = ({
    items,
    focusedItem,
    setFocusedItem,
    handleSelect,
    setShowDropdown,
}) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            let currentIndex = items.findIndex(
                (country) => country.code === focusedItem.code
            );

            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    currentIndex = (currentIndex + 1) % items.length;
                    setFocusedItem(items[currentIndex]);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    currentIndex =
                        (currentIndex - 1 + items.length) % items.length;
                    setFocusedItem(items[currentIndex]);
                    break;
                case 'Enter':
                    handleSelect(items[currentIndex]);
                    break;
                case 'Escape':
                    setShowDropdown(false);
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [items, focusedItem, handleSelect, setFocusedItem, setShowDropdown]);
};

export default useKeyboardNavigation;
