export const navbarMenu = [{ title: 'All investors', url: '/dashboard' }];
export const menu = [
    {
        labelName: 'All investors',
        iconSrc: '/images/darkProfile.svg',
        linkSrc: '/dashboard',
    },
    {
        labelName: 'Onboard Investor',
        iconSrc: '/images/addUser.svg',
        linkSrc: '/onboard-investor',
    },
];

export const panAlreadyExistMessage = `The PAN you have entered is already associated with another account on Jiraaf. You cannot have the same PAN associated with more than one account with us.`;
export const panNameMismatchForMigratedUser = `Your PAN could not be verified as the name on PAN doesn't match with investor name. Please reach out to us at <a href='mailto:help@jiraaf.com' class='p4-underline text-primary-500'>help@jiraaf.com</a> for support.`

export const addressProofTypes = [
    { name: 'Aadhar Card', id: 'Aadhar' },
    { name: 'Voter Id', id: 'VoterId' },
    { name: 'Passport', id: 'Passport' },
];

export const maxFileSize = 2000;

export const INVESTOR_PROFILE_BUCKET = process.env.NEXT_PUBLIC_INVESTOR_PROFILE_PHOTO;

export const getHeaderAndDescriptionForDoubleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description: 'Your Details should be clearly readable',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description: 'Your Details should be clearly readable',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description: 'Your Details should be clearly readable',
            };
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png',
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png',
                },
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png',
                },
                back: {
                    header: 'Upload back side of Voter Id',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png',
                },
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload Photo page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png',
                },
                back: {
                    header: 'Upload Address page of Passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png',
                },
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png',
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png',
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png',
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'If you have a two seperate images for Aadhar and wish to upload each side separately, click',
        VoterId:
            'If you have a two seperate images for Voter ID and wish to upload each side separately, click',
        Passport:
            'If you have a two seperate images for Passport and wish to upload each side separately, click',
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: "Don't have a two-sided for Aadhaar?\nTo upload a single page Aadhaar click",
        VoterId:
            "Don't have a two seperated images for Voter ID?\nTo upload a single file of Voter ID click",
        Passport:
            "Don't have a two seperated images for Passport?\nTo upload a single file of Passport click",
    };
    return addressTypes[addressType];
};

export const allowedFileUploads = 'image/png, image/jpeg, image/jpg';

export const getHeaderAndDescriptionForSingleUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                header: 'Please upload your Aadhaar Card',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'VoterId':
            return {
                header: 'Please upload your Voter ID',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        case 'Passport':
            return {
                header: 'Please upload your Passport',
                description:
                    'Please ensure that both the address and your photo is visible in the same file/image',
            };
        default:
            break;
    }
};
export const ifaMasterCommisionPercentage = 1;

export const environment = ['production'].includes(
    process.env.NEXT_PUBLIC_CURRENT_ENV
) ? 'production' : 'sandbox';

export const otpLength = 6;

export const resendOtpErrorWord = 'resend';

export const ZERO_COUPON_BOND = 'Zero-Coupon Corporate Bond'