import {
    getAuth,
    isAuthenticated,
    removeAuth,
    removeReferralCode,
    removeReferralEntityType,
    setAuth
} from '@services/identity.service';
import { create } from 'zustand';
import { toast } from 'react-toastify';
import tailwindConfig from 'tailwind.config';
import Cookies from 'universal-cookie';
import { Text } from '@ui/atoms';
import { investorService } from '@services/middlewares/investor.middleware';
import { createJSONStorage, persist } from 'zustand/middleware';
import { logoutAuth } from '@services/logout.service';

const cookies = new Cookies();

const destroySession = (
    toastMessage = 'Your session has expired, please login again.',
    color = tailwindConfig.theme.colors.primary['500'],
    ...props
) => {
    toast.dismiss();
    toast.info(
        <Text
            content={toastMessage}
            className="p6-medium md:p5-medium text-primary-900"
        />,
        {
            ...props,
            position: 'top-center',
            progress: null,
            closeButton: false,
            hideProgressBar: true,
            style: {
                '--toastify-color-progress-info': color, // Replace with your desired progress bar color
                '--toastify-icon-color-info': color,
            },
            autoClose: 5000,
        }
    );
};

const removeAllPersistStore = () => {
    sessionStorage.clear();
    Object.keys(localStorage).forEach((key) => {
        if (key !== 'MOE_DATA')
            localStorage.removeItem(key);
    });
    Object.keys(cookies.getAll()).forEach((cookie) => {
        if (cookie !== 'moe_uuid')
            cookies.remove(cookie);
    });
};

const fetchInvestor = async (forceFetch) => {
    const auth = getAuth();
    const authenticated = await isAuthenticated(auth);
    try {
        if (authenticated && auth?.accountType === 'individual') {
            const investor = await investorService.get(auth.id, forceFetch);

            if (investor) {
                useAuthStore.setState({
                    investor,
                });
            } else {
                useAuthStore.setState({
                    investor: null,
                });
            }
            return investor;
        }
    } catch (error) {
        console.error('Error fetching investor:', error);
        useAuthStore.setState({
            investor: null,
        });
    }
};

const useAuthStore = create(
    persist(
        (set, get) => ({
            user: null,
            loading: true,
            isLogged: null,
            authenticated: null,
            investor: null,

            // Actions
            setLoading: (loading) => set({ loading }),
            setUser: (user) => set({ user }),
            setInvestor: (investor) => set((state) => ({ ...state, investor })),
            login: (userData, forceFetch = true) => {
                setAuth(userData);
                fetchInvestor(forceFetch);
                set({
                    user: userData,
                    isLogged: true,
                });
                const channel = new BroadcastChannel('authChannel');
                channel.postMessage({
                    action: 'login',
                    userData,
                });
                channel.close();
            },

            fetchInvestor: async () => {
                const auth = getAuth();
                const authenticated = await isAuthenticated(auth);
                if (authenticated) {
                    const investorResponse = await fetchInvestor(true);
                    return investorResponse;
                }
            },

            logout: async (router) => {
                const auth = getAuth();
                if (useAuthStore.getState().isLogged) {
                    !auth && destroySession();
                }
                // if redirect query param exist in url then we need push user to / with that query
                let redirect = router?.query?.redirect || '';
                set({
                    user: null,
                    isLogged: false,
                    authenticated: false,
                    investor: null,
                });
                localStorage.removeItem('investor');
                removeAuth();
                logoutAuth();
                removeReferralCode();
                removeReferralEntityType();
                removeAllPersistStore();
                const channel = new BroadcastChannel('authChannel');
                channel.postMessage({ action: 'logout' });
                channel.close();
                router.replace(redirect ? `/?redirect=${redirect}` : '/');
            },

            initialize: async () => {
                const auth = getAuth();
                const authenticated = await isAuthenticated(auth);
                if (authenticated) {
                    set({
                        user: auth,
                        isLogged: true,
                        authenticated: true,
                    });
                } else {
                    set({
                        user: null,
                        isLogged: false,
                        isAuthenticated: false,
                        investor: null,
                    });
                }
            },
        }),
        {
            name: 'investor', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
            partialize: (state) => ({
                investor: state.investor,
            }),
        }
    )
);

export default useAuthStore;
