import texts from '@components/Homepage/en.json';
import { CB_PRODUCT_ROUTE, GSEC_SDL_PRODUCT_ROUTE, SGB_PRODUCT_ROUTE, TB_PRODUCT_ROUTE } from '@ui/lib/constants/routes';

export const ourInvestors = [
    {
        image: '/images/homepage/investors/Siddharth Shah.png',
        name: 'Siddharth Shah',
        position: 'Co-founder, Pharmeasy',
        alt: 'siddharth shah cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Mankekar.svg',
        name: 'Mankekar',
        position: 'Family Office',
        alt: 'Mankekar',
    },
    {
        image: '/images/homepage/investors/Capital A.svg',
        name: 'Capital - A',
        position: 'Venture Capital',
        alt: 'Capital - A',
    },
    {
        image: '/images/homepage/investors/Dharmil Sheth.png',
        name: 'Dharmil Sheth',
        position: 'Co-founder, Pharmeasy',
        alt: 'dharmil sheth cofounder of pharmeasy',
    },
    {
        image: '/images/homepage/investors/Ramakant Sharma.png',
        name: 'Ramakant Sharma',
        position: 'Co-founder, LivSpace',
        alt: 'ramakant sharma cofounder livspace',
    },
];

export const mediaCoverages = [
    {
        image: '/images/media-coverage/economic-times.png',
        url: 'https://economictimes.indiatimes.com/tech/funding/fintech-startup-jiraaf-raises-8-7-million-in-funding-led-by-accel-harmony-partners/articleshow/104349942.cms?from=mdr',
        alt: 'economic-times'
    },
    {
        image: '/images/media-coverage/cnbc.png',
        url: 'https://www.cnbctv18.com/startup/startup-digest-jiraaf-raises-fund-groww-surpasses-zerodha-active-investor-count-indians-in-festive-spending-18037691.htm?utm_content=buffera9082&utm_medium=social&utm_source=linkedin.com&utm_campaign=buffer',
        alt: 'cnbc'
    },
    {
        image: '/images/media-coverage/bloomberg.png',
        url: 'https://www.bloomberg.com/news/articles/2022-06-13/millennials-hunting-returns-drive-risk-hungry-investing-in-india',
        alt: 'bloomberg'
    },
    {
        image: '/images/media-coverage/financial-express.png',
        url: 'https://www.financialexpress.com/money/how-financial-habits-can-improve-your-wealth-2693175/',
        alt: 'financial-express'
    },
    {
        image: '/images/media-coverage/business-standard.png',
        url: 'https://www.business-standard.com/amp/content/press-releases-ani/fintech-platform-jiraaf-offers-fixed-income-options-to-investors-during-akshaya-tritiya-123042100841_1.html',
        alt: 'business-standard'
    },
    {
        image: '/images/media-coverage/mint.png',
        url: 'https://www.livemint.com/money/personal-finance/quick-financial-checks-before-the-year-ends-11679308957716.html',
        alt: 'mint'
    },
    {
        image: '/images/media-coverage/the-times-of-india.png',
        url: 'https://timesofindia.indiatimes.com/blogs/voices/the-romance-of-investing-your-money-deserves-love-and-attention/',
        alt: 'the-times-of-india'
    },
    {
        image: '/images/media-coverage/your-story.png',
        url: 'https://yourstory.com/2023/10/funding-fixed-income-platform-jiraaf-series-b-accel-harmony-partners',
        alt: 'your-story'
    },
    {
        image: '/images/media-coverage/inc42.png',
        url: 'https://inc42.com/buzz/jiraaf-raises-8-7-mn-funding-to-help-investors-diversify-fixed-income-investments/',
        alt: 'inc42'
    },
    {
        image: '/images/media-coverage/ndtv-profit.png',
        url: 'https://www.ndtvprofit.com/business/fixed-income-investment-platform-jiraaf-raises-87-million',
        alt: 'ndtv-profit'
    },
    {
        image: '/images/media-coverage/business-line.png',
        url: 'https://www.thehindubusinessline.com/portfolio/personal-finance/alternative-fixed-income-investments-risks-gains-and-why-invest-in-them/article66720337.ece',
        alt: 'business-line'
    },
    {
        image: '/images/media-coverage/the-indian-express.png',
        url: 'https://www.newindianexpress.com/business/2022/Aug/29/what-it-takes-to-build-rs-5-crore-retirement-corpus-2492603.html',
        alt: 'the-indian-express'
    },
]

export const BannerData = [
    {
        header: texts?.Diversify,
        description: texts?.DiversifyDescription,
        image: '/images/homepage/Diversify.svg',
    },
    {
        header: texts?.Explore,
        description: texts?.ExploreDescription,
        image: '/images/homepage/explore.svg',
    },
    {
        header: texts?.Invest,
        description: texts?.InvestDescription,
        image: '/images/homepage/invest.svg',
    },
    {
        header: texts?.Maximise,
        description: texts?.MaximiseDescription,
        image: '/images/homepage/maximise.svg',
    },
];

export const WhyInvestData = [
    {
        text: texts?.FixedIncomeOpportunities,
        image: '/images/homepage/dots.svg',
        alt: 'dots'
    },
    {
        text: texts?.RelationshipManager,
        image: '/images/homepage/frame.svg',
        alt: 'frame'
    },
    {
        text: texts?.TeamOfExperts,
        image: '/images/homepage/grid.svg',
        alt: 'grid'
    },
    {
        text: texts?.AccessToInformation,
        image: '/images/homepage/pipes.svg',
        alt: 'pipes'
    },
];

export const ProductsData = [
    {
        image: '/images/homepage/cb.svg',
        header: texts?.ListedCorporateBonds,
        yieldValue: '8%-18%',
        cta: CB_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/tb.svg',
        header: texts?.TreasuryBills,
        yieldValue: '6.5%-7.5%',
        cta: TB_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/gsec.svg',
        header: texts?.Gsec,
        yieldValue: '7% and above',
        cta: GSEC_SDL_PRODUCT_ROUTE,
    },
    {
        image: '/images/homepage/sgb.svg',
        header: texts?.SovereignGoldBonds,
        yieldValue: '2.5% (Fixed) + Variable',
        cta: SGB_PRODUCT_ROUTE,
    }
];
export const InvestmentOptionsData = [
    {
        image: '/images/homepage/low-risk.svg',
        header: texts?.LowRisk,
        description: texts?.LowRiskDescription,
    },
    {
        image: '/images/homepage/passive-income.svg',
        header: texts?.SecondaryIncome,
        description: texts?.SecondaryIncomeDescription,
    },
    {
        image: '/images/products/secure.svg',
        header: texts?.HighPriorityClaims,
        description: texts?.HighPriorityClaimsDescription,
    },
    {
        image: '/images/products/bill-cross.svg',
        header: texts?.TaxOptimization,
        description: texts?.TaxOptimizationDescription,
    },
    {
        image: '/images/products/stability.svg',
        header: texts?.TenureOptions,
        description: texts?.TenureOptionsDescription,
    }
];

export const FinancialProsperityData = [
    {
        header: texts?.PortfolioDiversification,
        description: texts?.PortfolioDiversificationDescription,
    },
    {
        header: texts?.BigReturnsSmallInvestments,
        description: texts?.BigReturnsSmallInvestmentsDescription,
    },
    {
        header: texts?.PlannedPayouts,
        description: texts?.PlannedPayoutsDescription,
    },
];

export const RegulatedFrameworkData = [
    {
        title: texts?.RegulatorySales,
        description: texts?.RegulatorySalesDescription,
    },
    {
        title: texts?.CreditRating,
        description: texts?.CreditRatingDescription,
        images: [
            {
                altName: 'icra',
                url: '/images/homepage/regulatory-logos/icra.png'
            },
            {
                altName: 'crisil',
                url: '/images/homepage/regulatory-logos/crisil.png'
            },
            {
                altName: 'care-edge',
                url: '/images/homepage/regulatory-logos/care.png'
            },
        ],
    },
    {
        title: texts?.DebentureTrustees,
        description: texts?.DebentureTrusteesDescription,
        images: [
            {
                altName: 'catalyst',
                url: '/images/homepage/regulatory-logos/catalyst.png'
            },
            {
                altName: 'axis-bank',
                url: '/images/homepage/regulatory-logos/axis-bank.png'
            }, {
                altName: 'sbi-cap',
                url: '/images/homepage/regulatory-logos/sbicap.png'
            }, {
                altName: 'vistra-itcl',
                url: '/images/homepage/regulatory-logos/vistra.png'
            },
        ],
    },
];

export const RecentDealsData = [
    {
        isClosed: false,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    },
    {
        isClosed: false,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    },
    {
        isClosed: true,
        companyLogo: '/images/homepage/muthoot.svg',
        companyName: 'Piramal Capital and Housing Finance Limited...',
        industryType: 'Food & Beverages',
        bondType: 'Rural Electrification Bond',
        opportunityType: 'Securitised Debt (SDI)',
        displayIRR: '13.00',
        tenure: '2Y 31D',
        minInvestment: 91000,
        interestPayoutFrequency: 'Monthly',
        principalPayoutFrequency: 'Quarterly',
        riskRatingName: 'CRISIL AA',
        riskCategory: 'Medium',
    }
];