import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';

import Button from '@ui/atoms/design-system/Button';
import { Text } from '@ui/atoms';
import { CustomStepSlider } from '@ui/atoms/design-system/custom-range-slider';
import { Switch } from '@ui/atoms/design-system/switch';

import { cn } from '@ui/atoms/utils/utils';
import { buttonTypes } from '@ui/atoms/utils/enums';
import { updateQueryParams } from '@lib/url';
import {
    CONSTANTS,
    customMinInvestmentSteps,
    FILTER_OPTIONS,
    opportunitiesFilterDefaultValues,
} from '@components/Investor/Opportunities/Filters/utils/helper';
import { filterSchema } from '@components/Investor/Opportunities/Filters/utils/form-schema';
import {
    getRatingHelperText,
    labelFormatter,
    parseAmount,
    parseQueryValue,
} from '@components/Investor/Opportunities/Filters/utils/utils';

const FilterModal = ({ onClose }) => {
    const router = useRouter();
    const { query } = router;

    const { control, handleSubmit, watch, reset, getValues } = useForm({
        resolver: yupResolver(filterSchema),
        defaultValues: opportunitiesFilterDefaultValues,
    });

    useEffect(() => {
        const filters = {
            yield: parseQueryValue(query.yield),
            tenure: parseQueryValue(query.tenure),
            amount: parseAmount(query.amount),
            rating: parseQueryValue(query.rating),
            secured: query.secured === 'true',
        };

        reset(filters);
    }, [query, reset]);

    const filters = watch();

    const clearFilters = () => {
        reset(opportunitiesFilterDefaultValues);
        router.push({ query: {} });
        onClose();
    };

    const onSubmit = (data) => {
        const paramsToAdd = {};
        const paramsToRemove = [];

        Object.entries(data).forEach(([key, value]) => {
            if (key === 'amount') {
                if (
                    value[0] === CONSTANTS.MIN_INVESTMENT.toString() &&
                    value[1] === CONSTANTS.MAX_INVESTMENT.toString()
                ) {
                    paramsToRemove.push(key);
                } else {
                    if (
                        value[1] >=
                        customMinInvestmentSteps[
                            customMinInvestmentSteps.length - 1
                        ]
                    ) {
                        paramsToAdd[key] = `>=${value[0]}`;
                    } else {
                        paramsToAdd[key] = value.join('-');
                    }
                }
            } else if (Array.isArray(value) && value.length > 0) {
                paramsToAdd[key] = value;
            } else if (typeof value === 'boolean' && value) {
                paramsToAdd[key] = value;
            } else {
                paramsToRemove.push(key);
            }
        });

        const queryString = updateQueryParams(
            query,
            paramsToAdd,
            paramsToRemove
        );
        router.push(
            queryString
                ? `${window.location.pathname}?${queryString}`
                : window.location.pathname
        );
        onClose();
    };

    const FilterOptions = ({
        title,
        filterType,
        options,
        showHelperText = false,
    }) => {
        return (
            <div className="flex flex-col gap-3">
                <Text content={title} className="text-gray-900 h6-semibold" />
                <div className="flex items-center gap-2">
                    {options.map(({ value, label }) => (
                        <Controller
                            key={value}
                            name={filterType}
                            control={control}
                            render={({ field }) => (
                                <div
                                    className={cn(
                                        'flex items-center gap-2 p-2 border rounded-full bg-basicWhite border-gray-200 text-gray-500 cursor-pointer',
                                        {
                                            'bg-primary-50 border-primary-70 text-primary-400':
                                                field.value.includes(value),
                                        }
                                    )}
                                    onClick={() => {
                                        field.onChange(
                                            field.value.includes(value)
                                                ? field.value.filter(
                                                      (item) => item !== value
                                                  )
                                                : [...field.value, value]
                                        );
                                    }}
                                >
                                    <Text
                                        content={label}
                                        className="p5-regular"
                                    />
                                </div>
                            )}
                        />
                    ))}
                </div>
                {showHelperText && getRatingHelperText(getValues('rating')) ? (
                    <span className={cn('p5-regular text-gray-500')}>
                        {getRatingHelperText(getValues('rating'))}
                    </span>
                ) : null}
            </div>
        );
    };

    return (
        <form
            className="relative flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex flex-col gap-4 p-4">
                {Object.entries(FILTER_OPTIONS)
                    .splice(0, Object.keys(FILTER_OPTIONS).length - 1)
                    .map(([key, options]) => (
                        <FilterOptions
                            key={key}
                            title={key.charAt(0).toUpperCase() + key.slice(1)}
                            filterType={key}
                            options={options}
                        />
                    ))}
                <div className="flex flex-col gap-3">
                    <Text
                        content="Minimum Investment"
                        className="text-gray-900 h6-semibold"
                    />
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => (
                            <CustomStepSlider
                                min={CONSTANTS.MIN_INVESTMENT}
                                max={CONSTANTS.MAX_INVESTMENT}
                                step={CONSTANTS.STEP}
                                value={field.value}
                                defaultValues={CONSTANTS.MIN_MAX_AMOUNT}
                                valueClassName="text-primary-400 h6-regular"
                                onChange={field.onChange}
                                steps={customMinInvestmentSteps}
                                labelFormatter={labelFormatter}
                            />
                        )}
                    />
                </div>
                <FilterOptions
                    title="Rating"
                    filterType="rating"
                    options={FILTER_OPTIONS.rating}
                    showHelperText
                />
                <div className="flex items-center gap-4 py-2">
                    <Controller
                        name="secured"
                        control={control}
                        render={({ field }) => (
                            <Switch
                                checked={field.value}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    <Text
                        content="Show secured opportunities only"
                        className="text-gray-500 p4-regular"
                    />
                </div>
            </div>
            <div className="sticky bottom-0 w-full p-4 border-t bg-basicWhite md:bottom-0 md:relative">
                <div className="flex items-center gap-4">
                    <Button
                        buttonText="Clear All"
                        buttonType={buttonTypes.TERTIARY}
                        widthClass="w-full max-w-[92px]"
                        onClick={clearFilters}
                        type="button"
                    />
                    <Button
                        buttonText="Apply Filters"
                        buttonType={buttonTypes.PRIMARY}
                        widthClass="flex-1 w-full"
                    />
                </div>
            </div>
        </form>
    );
};

export default FilterModal;
