export const backgroundImage = {
    'opportunity-sticky-actions-sm':
        'linear-gradient(180deg, transparent 23%, rgba(247, 247, 254) 13%)',
    'opportunity-sticky-actions':
        'linear-gradient(180deg, transparent 50%, rgba(247, 247, 254) 50%)',
    'jiraaf-light-gray-bg-left':
        'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-left.png)',
    'jiraaf-light-gray-bg-right':
        'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-right.png)',
    'jiraaf-light-gray-bg-left-mobile':
        'url(/images/CreditEvaluationProcess/jiraaf-light-gray-bg-left-mobile.png)',
    'green-gradient':
        'linear-gradient(265deg, #69B21B 4.15%, #1D664A 95.85%)',
    'yellow-gradient':
        'linear-gradient(90deg, #FFEACB 0%, #FFBA35 97.73%)',
    'kyc-bg':
        'linear-gradient(90deg, #289A3F 0%, #289A3F 50%, #D2F3D5 50%, #D2F3D5 100%)',
    'welcome-asset-card-mobile':
        'linear-gradient(270deg, #FEF2DB 0%, #FFF9EF 100%)',
    'welcome-asset-card':
        'linear-gradient(90deg, #FFF9EF 0%, #FFF 100%)',
    'welcome-asset-card-hover':
        'linear-gradient(90deg, #FFF 0%, #FEF2DB 100%)',
    'blue-gradient': 'linear-gradient(267deg, #0054AD 6.77%, #003975 99.57%)',
    'refer-and-earn-steps': 'linear-gradient(90deg, #FFEACB 0%, #FFBA35 97.73%)',
    'blue-brand': "linear-gradient(267deg, #0054AD 6.77%, #003975 99.57%)",
    'festive-opportunity-card-border-gradient': 'linear-gradient(143deg, #D7BC5F -1.67%, #FFF2C6 23.45%, #D9AF1B 48.05%, #FFE99B 72.65%, #B49528 98.82%)',
    'festive-opportunity-card-irr': 'linear-gradient(270deg, #FFF5E3 0%, #FFF 100%)',
    'festive-gold-gradient': 'linear-gradient(92deg, #F3E2C2 0%, #FDF5DF 48.21%, #F3E2C2 96.02%)',
    'festive-gradient':
        'linear-gradient(90deg, #FFF 0%, #FFBA35 49.5%, #FFF 100%)',
    'festive-gradient-desktop':
        'linear-gradient(90deg, #FFBA35 0%, #FFF 100%)',
    'festive-radial-gradient':
        'radial-gradient(80.3% 82.35% at 52.74% 50.04%, #006BDB 0%, #003975 100%)',
    'kyc-steps': 'linear-gradient(90deg, #F6FBFF 0%, #FFF 100%)',
    'kyc-steps-no': 'var(--Gradient-Blue, linear-gradient(267deg, #0054AD 6.77%, #003975 99.57%))'
}