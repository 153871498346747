import { Controller } from 'react-hook-form';
import Select, { createFilter, components } from 'react-select';
import Search from '../public/images/Search.svg';
import Image from './Image';
import { theme as tailwindTheme } from 'tailwind.config';
const SelectWithImage = ({
    label,
    options,
    control,
    name,
    error,
    setValue,
    onChange,
    withImage = false,
    register,
    customFormatOptionlabel,
    containerClassName = 'flex items-center',
    optionsWidth = 'w-full',
    components,
    controlStyles = { padding: 10 },
    optionBgColor = tailwindTheme?.colors?.gray?.[100],
    isDisabled = false,
    disabledOpacity = 0.5,
    primaryColor = tailwindTheme?.colors?.primary?.[500],
    height = '58px',
    containerBgColor = 'white',
    fontSize = '14px',
    ...inputProps
}) => {
    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
    };
    const customTheme = (theme) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: tailwindTheme?.colors?.gray?.[100],
            },
        };
    };
    const customStyles = {
        container: (provided, state) => {
            return {
                ...provided,
                flexGrow: 1,
                backgroundColor: containerBgColor,
                '&:focus': {
                    borderColor: primaryColor,
                    borderWidth: '2px',
                },
            };
        },
        valueContainer: (base) => {
            return {
                ...base,
                display: 'flex',
                flexWrap: 'nowrap',
                padding: '2px 0px 8px 2px',
                fontSize,
            };
        },
        option: (provided, state) => {
            return {
                ...provided,
                cursor: 'pointer',
                backgroundColor: state.isSelected ? optionBgColor : 'white',
                color: state.isSelected ? primaryColor : 'black',
            };
        },
        control: (provided, state) => {
            return {
                ...provided,
                ...controlStyles,
                backgroundColor: state.isDisabled
                    ? tailwindTheme?.colors?.gray?.[100]
                    : controlStyles.backgroundColor,
                borderColor: state.isFocused
                    ? primaryColor
                    : tailwindTheme?.colors?.gray?.[300],
                borderWidth: state.isFocused ? '2px' : '1px',
                cursor: 'pointer',
                height: height,
                alignItems: 'center',
                '&:hover': {
                    borderWidth: '2px',
                },
                '&:focus': {
                    borderColor: tailwindTheme?.colors?.green?.[500],
                    borderWidth: '2px',
                },
            };
        },
        input: (provided, state) => {
            return {
                ...provided,
            };
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? disabledOpacity : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
                maxWidth: '100%',
            };
        },
        indicatorSeparator: (provided, state) => {
            return {
                ...provided,
                height: '24px',
            };
        },
        clearIndicator: (provided, state) => {
            return {
                ...provided,
                height: '24px',
                alignSelf: 'self-start',
            };
        },
        dropdownIndicator: (provided, state) => {
            return {
                ...provided,
                height: '24px',
                alignSelf: 'self-start',
            };
        },
        input: (provided, state) => {
            return {
                ...provided,
                height: '24px',
                width: '24px',
                alignSelf: 'self-start',
            };
        },
    };
    const handleOnChange = (data) => onChange(data);

    return (
        <div className="flex flex-col cursor-pointer">
            {label && (
                <label className="mb-2 text-gray-900 p5-regular md:p4-regular">
                    {label}
                </label>
            )}
            <Controller
                as={Select}
                name={name}
                control={control}
                rules={{
                    required: {
                        message: 'Item type is required.',
                    },
                }}
                render={({ field: { onChange, value, ref, name } }) => {
                    return (
                        <div
                            className={`relative cursor-pointer ${
                                inputProps.isHighLighted &&
                                'border-[3px] border-yellow-500 rounded-md'
                            }`}
                        >
                            <Select
                                {...register(name)}
                                theme={customTheme}
                                inputRef={ref}
                                styles={customStyles}
                                closeMenuOnSelect={true}
                                options={options}
                                menuPosition={
                                    inputProps?.menuPlacement || 'bottom'
                                }
                                classNamePrefix="react-select"
                                placeholder={
                                    inputProps.placeholder
                                        ? inputProps.placeholder
                                        : 'Select'
                                }
                                isSearchable={inputProps?.isSearchable || true}
                                value={
                                    !inputProps.enableRemove
                                        ? options.find((c) => c.value === value)
                                        : options.find(
                                              (c) => c.value === value
                                          ) || 'Select'
                                }
                                isClearable={
                                    inputProps.isClearable
                                        ? inputProps.isClearable
                                        : false
                                }
                                isDisabled={isDisabled}
                                components={
                                    inputProps.searchImage && {
                                        ValueContainer,
                                    }
                                }
                                onChange={handleOnChange}
                                filterOption={createFilter(filterConfig)}
                                formatOptionLabel={(val) => {
                                    if (customFormatOptionlabel) {
                                        return customFormatOptionlabel(val);
                                    }
                                }}
                                error={error}
                                {...inputProps}
                            />
                            {inputProps.enableRemove && value && (
                                <div
                                    className="absolute top-[21px] right-14"
                                    onClick={inputProps.handleRemove}
                                >
                                    <Image
                                        src={'/images/close-hamburger.svg'}
                                        alt={'close'}
                                        className="w-5 h-4 cursor-pointer opacity-30 hover:opacity-100"
                                    />
                                </div>
                            )}
                        </div>
                    );
                }}
            />
            {error && !error?.message && (
                <div className="mt-2 text-left text-semantic-error-base p5-regular">
                    {error}
                </div>
            )}
            {error?.message && (
                <div
                    className={`mt-2 text-left text-semantic-error-base  p5-regular ${inputProps.errorClassName}`}
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!children && (
                    <Image
                        src={Search.src}
                        className={`w-5 h-5 self-center cursor-pointer`}
                    />
                )}
                {children}
            </components.ValueContainer>
        )
    );
};
export default SelectWithImage;
